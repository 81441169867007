@import url(https://fonts.googleapis.com/css?family=Montserrat);
@import url(https://fonts.googleapis.com/css?family=Geologica);

/* :root {
  --cWidth: clamp(300px, 90vw, 900px);
  --pad: clamp(10px, 15vw, 350px)
} */

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

.App {
  font-family: 'Montserrat';
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  transition: 0.3s;
  position: relative;
}

#nav {
  position: sticky;
  position: -webkit-sticky;

  top: 0;
  height: 100px;
  /* width: var(--cWidth); */
  width: 100vw;
  /* width: clamp(0px, 90vw, 1200px); */
  padding: 0 5vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  /* flex-wrap: wrap; */
  z-index: 10;
}

.menu {
  height: 14px;
  width: 15px;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  left: 5vw;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}

.menuLine {
  height: 2px;
  width: 100%;
  background-color: #000;
}

#nav > h1 {
  color: black;
  font-size: clamp(1.3rem, 1.5vw, 1.5rem);
  font-weight: 900;
  cursor: pointer;
  font-family: 'Geologica';
}

li {
  display: inline;
  margin-left: 20px;
  font-weight: bold;
  cursor: pointer;
  position: relative;
  font-size: clamp(0.95rem, 0.99vw, 10rem);
}

#nav li::after {
	content: "";
	height: 2px;
	width: 0%;
	background-color: #000;
	position: absolute;
	bottom: 0px;
  left: 50%;
  transform: translate(-50%, 3px);
	transition: all ease-in-out 300ms;
}

#nav li:hover::after {
	width: 100%;
}

.sideMenu {
  position: absolute;
  display: none;
  left: -120px;
  top: 0;
  width: 120px;
  height: 100%;
  background-color: #f2f4f7;
}

.menuSticky {
  position: sticky;
  position: -webkit-sticky;
  height: 100vh;
  height: 100svh;
  top: 0;
}

.sideMenu ul {
  margin-left: 5px;
  margin-bottom: 30px;
}

.sideMenu li {
  display: flex;
  margin-top: 20px;
}

.page {
  min-height: calc(100% - 100px);
  min-height: calc(100svh - 100px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: clamp(10px, 5vh, 50px) 5vw;
  width: clamp(0px, 90vw, 1200px);
}

.page h1 {
  align-self: flex-start;
  /* border-bottom: 2px solid black; */
}

.musicProjs {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  padding-top: 25px;
}

.tags {
  display: flex;
  column-gap: 5px;
  width: 100%;
  overflow-x: auto;
}

.tag {
  border-radius: 25px;
  border: 1px solid #ccc;
  padding: 4px 8px;
  cursor: pointer;
  transition: 0.3s;
  white-space: nowrap;
}

.selected {
  background-color: #075993;
  color: white;
  border: 1px solid white;
}

#about p {
  text-align: justify;
}

.dlcv {
  height: 20px;
}

#contact {
  justify-content: flex-start;
}

.contactBlock {
  width: 100%;
  margin-top: 15px;
}

.contactBlock p {
  display: flex;
  column-gap: 10px;
  margin-bottom: 15px;
}

.contactBlock a {
  display: flex;
  column-gap: 15px;
  text-decoration: none;
  color: #000;
}

.home {
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);
  overflow: hidden;
  background-color: #000;
  position: relative;
}

.bkImg {
  width: 110%;
  opacity: 0.3;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}

.text {
  color: white;
  z-index: 1;
  width: clamp(300px, 30vw, 1000px);
}

.ratings {
  display: flex;
  column-gap: 40px;
  width: 100%;
  flex-wrap: wrap;
  row-gap: 20px;
}

.rating {
  display: flex;
  position: relative;
  margin-top: 20px;
  align-items: center;
}

.rating img {
  opacity: 1;
  width: 25px;
  margin-right: 10px;
}

.rating span {
  opacity: 0;
  transition: 0.6s;
  position: absolute;
  top: 105%;
  white-space: nowrap;
}

.rating:hover span {
  opacity: 1;
}

.star {
  border-radius: 50%;
  border: 1px solid white;
  width: 10px;
  height: 10px;
  background-color: white;
  margin-left: 5px;
}

.empty {
  background-color: #00000000;
}

.beat {
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #777;
  width: 100%;
  height: 50px;
  column-gap: 10px;
  position: relative;
  margin-bottom: 20px;
  overflow: hidden;
}

.progress {
  background-color: #ddd;
  height: 100%;
  position: absolute;
  z-index: -1;
}

@media only screen and (max-width: 840px) {
  #centerCont {
    flex-direction: column-reverse;
  }

  .projBody {
    flex-direction: column;
    row-gap: 25px;
  }

  .projBody p {
    width: 100%;
  }

  .projBody img {
    width: 100%;
  }

  .bkImg {
    height: 110%;
    width: auto;
  }
} 

@media only screen and (max-width: 700px) {
  #nav ul {
    display: none;
  }

  .sideMenu {
    display: flex;
  }

  .menu {
    display: flex;
  }

  #nav {
    justify-content: center;
  }
} 
